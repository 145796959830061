

















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ParityFiltersService, { ParityFiltersServiceS } from '../../parity-filters.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import CustomMultiSelect from '../../../../../common/components/ui-kit/custom-multi-select.vue';

@Component({
    components: { CustomMultiSelect },
})
export default class ParityMileageFilter extends Vue {
    @Inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;

    get items(): Item[] {
        const { mileage } = this.parityFiltersService.settings;

        if (!mileage) {
            return [];
        }

        return mileage.map(value => ({
            name: String(value),
            value,
        }));
    }

    get currentValue() {
        if (!this.paritySettingsService.storeState.settings.mileage) {
            return [];
        }

        return this.paritySettingsService.storeState.settings.mileage.map(mileage => ({
            name: mileage,
            value: mileage,
        }));
    }
    set currentValue(value) {
        if (value) {
            this.paritySettingsService.storeState.settings.mileage = value.map(item => item.value);
        }
    }
}
