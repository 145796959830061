









import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import ParityFiltersService, { ParityFiltersServiceS } from '../../parity-filters.service';

@Component({
    components: { CustomMultiSelect },
})
export default class ParityCarClassesFilter extends Vue {
    @Inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;

    get items() {
        if (!this.parityFiltersService.settings.carClass) {
            return [];
        }

        return this.parityFiltersService.settings.carClass.map(val => ({
            value: val,
            name: val,
        }));
    }

    get currentValue() {
        if (!this.paritySettingsService.carClasses) {
            return [];
        }

        return this.paritySettingsService.carClasses.map(carClass => ({
            name: carClass,
            value: carClass,
        })) || [];
    }
    set currentValue(value) {
        if (value) {
            this.fleetFiltersService.saveCarClasses(value.map(item => item.value));
        }
    }
}
