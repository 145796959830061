




import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChartData } from 'chart.js';
import moment from 'moment';
import { IBml } from '../../interfaces/common.interface';
import BmlTooltip from '../bml-tooltip.vue';

@Component({
    components: { BmlTooltip },
})
export default class TrandTooltip extends Vue {
    @Prop({
        type: String,
    })
    private day!: string;

    @Prop({
        type: Object as () => ChartData,
    })
    private chartData!: ChartData;

    get date() {
        if (this.day === 'Today') {
            return moment().format('DD-MMM-YY');
        }

        return moment(this.day, 'DD/MM/YYYY').format('DD-MMM-YY');
    }

    get bml(): IBml {
        const { datasets, labels } = this.chartData;

        if (!datasets || !labels) {
            return {};
        }

        const currentLabelIndex = labels.findIndex(label => label === this.day);

        const beat = (datasets[3] && datasets[3].data) ? (datasets[3].data[currentLabelIndex] || 0) as number : 0;
        const meet = (datasets[2] && datasets[2].data) ? (datasets[2].data[currentLabelIndex] || 0) as number : 0;
        const lose = (datasets[1] && datasets[1].data) ? (datasets[1].data[currentLabelIndex] || 0) as number : 0;
        const na = (datasets[0] && datasets[0].data) ? (datasets[0].data[currentLabelIndex] || 0) as number : 0;

        return {
            B: beat - meet,
            M: meet - lose,
            L: lose - na,
            A: na,
        };
    }
}
