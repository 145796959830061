












import { Component, Vue } from 'vue-property-decorator';
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui';

@Component({
    components: {
        'el-dropdown': Dropdown,
        'el-dropdown-menu': DropdownMenu,
        'el-dropdown-item': DropdownItem,
    },
})
export default class FilterOptions extends Vue {
    save() {
        this.$emit('save');
    }

    clear() {
        this.$emit('clear');
    }
}
