




















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import ParityFiltersService, { ParityFiltersServiceS } from '@/modules/cars/modules/parity/parity-filters.service';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

@Component({
    components: { CustomMultiSelect, StyledSearchbar },
})
export default class ParityPickUpCityFilter extends Vue {
    @Inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    private query: string | null = null;

    get text() {
        return this.currentValue.length === 1
            ? this.currentValue[0].name
            : `${this.currentValue.length} selected`;
    }

    get items() {
        let { filteredLocations } = this.paritySettingsService;

        if (!filteredLocations) {
            return [];
        }

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            filteredLocations = filteredLocations.filter(location => searchPattern.test(location.locationName.toLowerCase()));
        }

        const result = filteredLocations.map(loc => ({
            name: loc.locationName,
            value: loc.locationId,
            disabled: false,
        })).sort((a, b) => {
            if (a.name <= b.name) {
                return -1;
            }
            return 1;
        });

        if (!result.length) {
            result.push({
                name: 'No results',
                value: 'No results',
                disabled: true,
            });
        }

        return result;
    }

    get currentValue() {
        const { locations } = this.parityFiltersService.settings;
        const { pickUpCities } = this.paritySettingsService;

        if (!locations || !pickUpCities) {
            return [];
        }

        const selected = pickUpCities
            .map(cityCode => {
                const location = locations.find(loc => loc.locationId === cityCode);

                if (!location) {
                    return { name: cityCode, value: cityCode };
                }

                return { name: location.locationName, value: location.locationId };
            });

        if (!selected) {
            return [];
        }

        return selected;
    }

    set currentValue(values) {
        if (values) {
            this.carsSharedService.savePickUpCitiesAndCountry(values.map(value => value.value), this.$route.path);
            this.paritySettingsService.pickUpCities = values.map(val => val.value);
        }
    }

    handleChange(query: string) {
        this.query = query;
    }
}
