import { render, staticRenderFns } from "./bml-tooltip.vue?vue&type=template&id=4039c078&scoped=true&"
import script from "./bml-tooltip.vue?vue&type=script&lang=ts&"
export * from "./bml-tooltip.vue?vue&type=script&lang=ts&"
import style0 from "./bml-tooltip.vue?vue&type=style&index=0&id=4039c078&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4039c078",
  null
  
)

export default component.exports