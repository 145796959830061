































import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import Search from '@/modules/common/components/ui-kit/search.vue';

@Component({
    components: { Search },
})
export default class StyledSearchbar extends Vue {
    // TODO: Replace by event (eg. on-typing-end)
    @Prop({
        type: Function,
        default: () => null,
    })
    afterType!: (query: string | null) => void;

    @Prop({
        type: Boolean,
        default: false,
    })
    hideSearchIcon!: boolean;

    @Prop({
        type: String,
        required: false,
    })
    defaultValue?: string;

    @Prop({
        type: String,
        required: false,
    })
    placeholder?: string;

    isFocused: boolean = false;

    onInput(query: string) {
        this.$emit('input', query);
    }

    handleClose() {
        interface VueHtmlElement extends HTMLElement, Vue {}

        const search = this.$refs.search as VueHtmlElement;
        const searchInput = search.$refs.searchInput as HTMLInputElement;

        if (searchInput.value) {
            searchInput.value = '';
            this.afterType(null);
        }

        this.blur();
    }

    handleFocus() {
        this.isFocused = true;
    }

    blur() {
        this.isFocused = false;
    }
}
