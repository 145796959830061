









import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';

@Component({
    components: { CustomMultiSelect },
})
export default class ParityDataSourcesFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;

    get options(): Item[] {
        const { availableDataSources } = this.carsFiltersService;
        const { chainName } = this.userService;

        if (!availableDataSources) {
            return [];
        }

        const brokers = availableDataSources.filter(item => ![chainName].includes(item));

        const data = brokers.map(value => ({
            name: value,
            value,
        }));

        return data;
    }

    get currentValue() {
        if (!this.paritySettingsService.storeState.settings.dataSources) {
            return [];
        }

        return this.paritySettingsService.storeState.settings.dataSources.map(dataSource => ({
            name: dataSource,
            value: dataSource,
        }));
    }
    set currentValue(value) {
        if (value) {
            this.paritySettingsService.dataSources = value.map(item => item.value);
        }
    }
}
