


































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IBml } from '../interfaces/common.interface';

@Component
export default class BmlTooltip extends Vue {
    @Prop({
        type: String,
    })
    date!: string;

    @Prop({
        type: String,
    })
    title!: string;

    @Prop({
        type: Object as () => IBml,
    })
    bml!: IBml;

    @Prop({
        type: Number,
        default: null,
    })
    total!: number | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    withPercents!: boolean;

    get parsedDate() {
        return this.date;
    }

    value(bmlValue?: number) {
        if (!bmlValue) {
            return `0${this.withPercents ? '%' : ''}`;
        }

        return `${bmlValue}${this.withPercents ? '%' : ''}`;
    }
}
