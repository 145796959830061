






import { Component, Vue } from 'vue-property-decorator';
import ParityFilterSet from '@/modules/cars/modules/parity/components/parity-filter-set.vue';
import PARITY_FILTER from '@/modules/cars/modules/parity/constants/parity-filter.constant';

@Component({
    components: {
        ParityFilterSet,
    },
})
export default class ParityActions extends Vue {
    get activeFilters(): PARITY_FILTER[] {
        // Return all possible parity filters
        return Object.entries(PARITY_FILTER).map(([key, value]) => value);
    }
}
