









import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import ParityFiltersService, { ParityFiltersServiceS } from '../../parity-filters.service';

@Component({
    components: { CustomMultiSelect },
})
export default class ParityPosFilter extends Vue {
    @Inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @Inject(UserServiceS) private userService!: UserService;

    get items(): Item[] {
        // const { pos } = this.parityFiltersService.settings;

        // if (!pos) {
        //     return [];
        // }

        // if (!this.currentValue || !pos.find(value => value === this.currentValue)) {
        //     const [defaultPos] = pos;
        //     this.currentValue = defaultPos;
        // }

        // return pos.map(value => ({
        //     name: value,
        //     value,
        // }));

        let { pos } = this.parityFiltersService.settings;

        if (!pos) {
            return [];
        }

        if (this.userService && this.userService.user && this.userService.user.chainName !== 'Rentalcars') {
            let posToInclude = ['GB', 'FR', 'DE', 'IT', 'ES', 'NL', 'US', 'BE'];
            if (this.userService.user.chainName === 'Lyft') {
                posToInclude = ['US'];
            }
            if (this.userService.user.chainName === 'Hertz') {
                posToInclude.push(...['PT', 'IE', 'AE', 'CH']);
            }
            if (this.userService.user.chainName === 'Avis') {
                posToInclude = ['US', 'MX'];
            }
            pos = pos.filter(item => posToInclude.includes(item));
        }

        return pos.map(value => ({
            name: String(value),
            value,
        }));
    }

    get currentValue() {
        if (!this.paritySettingsService.storeState.settings.pos) {
            return [];
        }

        return this.paritySettingsService.storeState.settings.pos.map(pos => ({
            name: pos,
            value: pos,
        }));
    }
    set currentValue(value) {
        if (value) {
            this.paritySettingsService.pos = value.map(item => item.value);
        }
    }
}
